
import Vue from "vue"
import axios from "axios"
import { dateDiff, dateFrancaise, dateTimeFrancaise, dbSAB, sqlDate, sqlDatetime, variables } from "../plugins/variables"
import { Zone, Cuvier, Machine, Transfert, ZonesPS, ZonesCAD, ZonesC, TransfertDA, ArrosageCuvier, CheckListeCategorie } from "../store/types"
const checklist = require("../assets/checkliste.json")
const zonePS = new ZonesPS()
const zoneCAD = new ZonesCAD()
const zoneC = new ZonesC()

// @ is an alias to /src
export default Vue.extend({
  name: "SAB",
  data() {
    return {
      zones: [] as Zone[],
      zone: new Zone(),
      cuviers: [] as Cuvier[],
      cuvierStokage: [] as Cuvier[],
      unCuvier: new Cuvier(),
      machines: [] as Machine[],
      choixMachine: [] as Machine[],
      utilisateurs: [],
      transferts: [] as Transfert[],
      tableauDeBord: [] as Transfert[],
      laitier: [] as string[],
      zonePS: zonePS,
      zoneCAD: zoneCAD,
      zoneC: zoneC,
      zonesAll: [zonePS, zoneCAD, zoneC],
      dictionnaire: [] as any[],
      nom_groupe: "",
      nom_cuvier: "",
      nomAffichageCamion: "",
      passerArrosage: false,
      nbVoyage: "" as number | string,
      imageCamion: "camionCuvier.svg",
      nom_zone: "",
      sl_code: "",
      derSlCode: "",
      st_numCharge: "",
      sc_observation: "",
      derNumCharge: "",
      mac_id: "", /// id porte-cuvier
      mac_desi: "" as string,
      tempsRestant: "0",
      transfertChaine: false,
      camionCuvier: false,
      camionCuvierLaitier: false,
      confirmationCamion: false,
      dialogue_choixCamion: false,
      dialogue_tableauDeBord: false,
      dialogue_numCharge: false,
      modifNumCharge: false,
      dialogue_Stokage: false,
      dialogue_PrendreDeposerStocker: false,
      dialogue_PrendreDeposerFour: false,
      dialogue_ViderCuvier: false,
      dialogue_avertissement: false,
      dialogue_commentaireCuvier: false,
      dialogue_nettoyage: false,
      dialogue_arrosage: false,
      dialogue_PrendreDeposerFosse: false,
      ut_id: 1,
      checkliste: JSON.parse(JSON.stringify(checklist)) as CheckListeCategorie[],
      checkListeChoisie: new CheckListeCategorie(),
      snak_visible: false,
      snakbar_text: "",
      unsubscribe: null as any,
      langue: variables.langue
    }
  },
  methods: {
    async initialiser() {
      this.zones = this.$store.state.zones
      this.cuviers = this.$store.state.cuviers
      this.machines = this.$store.state.machines
      this.utilisateurs = this.$store.state.utilisateur
      this.transferts = this.$store.state.transferts
      this.laitier = this.$store.state.laitier

      this.choixMachine = this.machines

      this.zonePS.initZone(this.zones)
      this.zoneC.initZone(this.zones)
      this.zoneCAD.initZone(this.zones)

      this.miseEnPlaceCuvier()

      const db = await dbSAB()
      const co = await db.getAll("sab_co")
      this.ut_id = co[0].ut_id
      const keyRangeValue = IDBKeyRange.only(co[0].ut_id)
      const machine_utiliser = await db.getAllFromIndex("machine_utiliser", "ut_id", keyRangeValue)

      this.iniCheckListe()

      if (machine_utiliser.length === 0) {
        this.ouvertureChoixCamion()
      } else {
        this.mac_desi = machine_utiliser[0].mac_desi
        this.mac_id = machine_utiliser[0].mac_id + ""
      }
      db.close
      this.ecrireIndexDB()
      this.choixCamionCuvier()
      if (this.dialogue_arrosage == true) {
        this.dureeArrosage()
      }
    },
    miseEnPlaceCuvier() {
      this.zonePS.initCuviers(this.cuviers)
      this.zoneCAD.initCuviers(this.cuviers)
      this.zoneC.initCuviers(this.cuviers)

      for (const unCuvier of this.cuviers) {
        const situationCuvier = unCuvier.getSituation().split("/")
        if (situationCuvier[0] == "Camion") {
          this.camionCuvier = true
          this.nom_cuvier = unCuvier.getCode()
          this.nom_groupe = unCuvier.getGroupe()
        } else if (situationCuvier[0] == "CamionLaitier") {
          this.camionCuvierLaitier = true
          this.nom_cuvier = unCuvier.getCode()
          this.nom_groupe = unCuvier.getGroupe()
          this.nom_zone = ""
        } else if (situationCuvier[0] != "Stocker") {
          let effectuer = this.zonePS.setNomCuvier(unCuvier.getCode(), situationCuvier[0])
          if (effectuer == false) {
            effectuer = this.zoneCAD.setNomCuvier(unCuvier.getCode(), situationCuvier[0])
            if (effectuer == false) {
              this.zoneC.setNomCuvier(unCuvier.getCode(), situationCuvier[0])
            }
          }
        }
      }
      this.desactiveZone()
    },
    async ecrireIndexDB() {
      const db = await dbSAB()
      const store = db.transaction("sab_cuvier", "readwrite").store
      for (const unCuvier of this.cuviers) {
        unCuvier.setAEnvoyerALaBD(false)
        await store.put(unCuvier)
      }
      const store2 = db.transaction("sab_transfert", "readwrite").store
      for (const unTransfert of this.transferts) {
        unTransfert.setAEnvoyerALaBD(false)
        await store2.put(unTransfert)
      }
      db.close
    },
    async lireIndexDB() {
      const db = await dbSAB()
      this.transferts = (await db.getAll("sab_transfert")).map((t: Transfert) => {
        return new Transfert(t)
      })
    },
    fermerDialogue() {
      this.dialogue_numCharge = false
      this.dialogue_Stokage = false
      this.dialogue_PrendreDeposerStocker = false
      this.dialogue_PrendreDeposerFour = false
      this.dialogue_ViderCuvier = false
      this.dialogue_choixCamion = false
      this.dialogue_PrendreDeposerFosse = false
    },
    async nomMachine(mac_id: number) {
      this.mac_desi = this.machines[this.machines.findIndex((uneMachine: Machine) => uneMachine.mac_id == mac_id)].mac_desi
      this.mac_id = mac_id + ""
      const db = await dbSAB()
      const co = await db.getAll("sab_co")
      const store = db.transaction("machine_utiliser", "readwrite").store
      await store.put({ ut_id: co[0].ut_id, date: new Date().getTime(), mac_id: this.mac_id, mac_desi: this.mac_desi })
    },
    trouver_sz_nomZone(sz_code: string) {
      if (sz_code != "" && sz_code !== null) {
        const code = sz_code.split(" ")
        if (sz_code == "Arrosage PS") {
          sz_code = "APS"
        } else if (sz_code == "Arrosage CAD") {
          sz_code = "ACAD"
        } else if (code[0] == "Fosse") {
          sz_code = "F" + code[1]
        }
        const laZone = this.zones.find((z: Zone) => z.getCode() == sz_code)
        if (laZone !== undefined) {
          if (this.$store.state.lang == "FR") {
            return laZone.getNomZone()
          } else {
            const sZone = laZone.getNomZone().split(" ")
            switch (sZone[0]) {
              case "Arrosage":
                return "Bewässerung " + sZone[1]
              case "Fosse":
                return "Beet " + sZone[1]
              case "Batiment":
                return "Gebäude"
              case "Stockage":
                return "Abkuhlen kübel " + sZone[2]
              default:
                return laZone.getNomZone()
            }
          }
        } else {
          return "Erreur"
        }
      }
      return ""
    },
    gererAffichageCuvier(sc_situation: string, sz_code: string) {
      if (sc_situation == "Camion/" + this.mac_id && sz_code.substring(0, 1) == "F") {
        //Chargement cuvier depuis fosse sur Camion
        this.camionCuvier = true
        this.nom_zone = ""
        for (const z of this.zonesAll) {
          const uneZone = z.trouveZone(sz_code)
          if (uneZone) {
            this.nom_cuvier = uneZone.getNomCuvier()
            uneZone.setNomCuvier("")
            this.nom_groupe = z.getZone()
          }
        }
      } else if (sc_situation == "Camion/" + this.mac_id) {
        //Chargement cuvier sur camion
        this.camionCuvier = true
      } else if (sc_situation == "CamionLaitier/" + this.mac_id) {
        //chargement cuvier rempli sur camion
        this.camionCuvier = false
        this.camionCuvierLaitier = true
        this.nom_zone = ""
        for (const z of this.zonesAll) {
          const uneZone = z.trouveZone(sz_code)
          if (uneZone) {
            this.nom_cuvier = uneZone.getNomCuvier()
            uneZone.setNomCuvier("")
            this.nom_groupe = z.getZone()
          }
        }
      } else if (sc_situation == sz_code) {
        //déchargement d'un cuvier dans un four
        this.camionCuvier = false
        this.camionCuvierLaitier = false
        this.nom_groupe = ""
        for (const z of this.zonesAll) {
          const uneZone = z.trouveZone(sz_code)
          if (uneZone) {
            uneZone.setNomCuvier(this.nom_cuvier)
          }
        }
        this.nom_cuvier = ""
      } else {
        //déchargemnent cuvier de camion
        this.nom_zone = ""
        this.camionCuvier = false
        this.camionCuvierLaitier = false
        this.nom_groupe = ""
        this.nom_cuvier = ""
      }
      this.choixCamionCuvier()
    },
    accepteCuvier(unCuvier: Cuvier, sz_code: string): boolean {
      if (sz_code == "Arrosage PS") {
        sz_code = "APS"
      }
      if (sz_code == "Arrosage CAD") {
        sz_code = "ACAD"
      }
      const groupeCuvier = unCuvier.getGroupe()
      for (const z of this.zonesAll) {
        if (z.trouveZone(sz_code) && groupeCuvier == z.getZone()) {
          if (z.accepteCuvier(unCuvier)) {
            return true
          } else {
            this.snackbarVisible(this.dictionnaire[27])
            return false
          }
        }
      }
      return false
    },
    async changerSituationCuvier(sc_code: string, sc_situationNew: string, sz_code: string, debutOuArriver: string) {
      let i = 0
      let trouve = false

      if (sc_situationNew == "Camion" || sc_situationNew == "CamionLaitier") {
        sc_situationNew += "/" + this.mac_id
      }
      while (i < this.cuviers.length && trouve === false) {
        const cuvier = this.cuviers[i]
        if (cuvier.getCode() == sc_code) {
          trouve = true
          if (this.accepteCuvier(cuvier, sz_code) == true) {
            if (this.camionCuvierLaitier == true && this.nom_groupe == "C" && debutOuArriver == "MAJ/CREE") {
              cuvier.aFaitUnVoyage(sc_situationNew)
            } else if (this.nom_groupe != "C") {
              this.nbVoyage = 0
            }
            this.nbVoyage = cuvier.getNbVoyage() + ""
            cuvier.setSituation(sc_situationNew, sqlDatetime(new Date()))
            this.fermerDialogue()
            const db = await dbSAB()
            const store = db.transaction("sab_cuvier", "readwrite").store
            cuvier.setAEnvoyerALaBD(true)
            await store.put(cuvier)
            db.close
            this.gererAffichageCuvier(sc_situationNew, sz_code)
            await this.enregistrementTransfert(sz_code, debutOuArriver, sc_code)
            this.desactiveZone()
            await this.initialiser()
          } else {
            this.snackbarVisible(this.dictionnaire[29])
            this.fermerDialogue()
          }
        }
        i++
      }
    },
    nouveauTransfert(leTransfert: Transfert, zoneAction: string, debutOuArriver: string, sc_code: string) {
      let reponse = "NON"
      const listeFour = ["C1", "C2", "C3", "PS", "CAD1", "CAD2", "CAD3"]
      if (
        sc_code == leTransfert.getCodeCuvier() &&
        ((zoneAction != "SC" && zoneAction != "SCAD" && zoneAction != "SPS") || ((zoneAction == "SC" || zoneAction == "SCAD" || zoneAction == "SPS") && debutOuArriver == "MAJ"))
      ) {
        let four = "" as string
        let i = 0
        while (four == "" && i < listeFour.length) {
          if (listeFour[i] == zoneAction) {
            four = listeFour[i]
          }
          i++
        }
        if (this.transfertChaine == true && sc_code == leTransfert.getCodeCuvier() && four != "") {
          this.transfertChaine = false
          return "EXISTE/NEW"
        } else {
          this.transfertChaine = false
          reponse = "EXISTE"
        }
      }
      if (debutOuArriver == "MAJ/CREE") {
        this.transfertChaine = true
      }
      return reponse
    },
    async enregistrementTransfert(zoneAction: string, debutOuArriver: string, sc_code: string) {
      const db = await dbSAB()
      const lesTransferts = (await db.getAll("sab_transfert")).map((t: Transfert) => {
        return new Transfert(t)
      })
      let newTrans = [] as string[]
      const mod = debutOuArriver.split("/")
      let i = lesTransferts.length
      let trouve = false
      while (0 < i && trouve == false) {
        i--
        newTrans = this.nouveauTransfert(lesTransferts[i], zoneAction, debutOuArriver, sc_code).split("/")
        if (newTrans[0] == "EXISTE") {
          trouve = true
        }
      }
      const store = db.transaction("sab_transfert", "readwrite").store
      if (!trouve) {
        const st_id = new Date().getTime() + ""
        await store.put({
          st_id: st_id,
          sc_code: sc_code,
          sl_code: this.sl_code,
          st_numCharge: this.st_numCharge,
          ut_id: this.ut_id,
          mac_id: this.mac_id,
          st_observation: null,
          AEnvoyerALaBD: true,
          DA: [{ st_id: st_id, stda_id: new Date().getTime() + "", stda_codeDepart: zoneAction, stda_codeArriver: null, stda_dateHeureDebut: sqlDatetime(new Date()), stda_dateHeureArriver: null }]
        })
      } else {
        if (mod[0] == "MAJ") {
          lesTransferts[i].setArriver(zoneAction, sqlDatetime(new Date()))
          lesTransferts[i].setAEnvoyerALaBD(true)
          await store.put(lesTransferts[i])
          this.lireIndexDB()
        }
        if (debutOuArriver == "CREE" || mod[1] == "CREE") {
          lesTransferts[i].getDA().push(new TransfertDA(lesTransferts[i].getId(), new Date().getTime() + "", zoneAction, null, sqlDatetime(new Date()), null))
          lesTransferts[i].setCodeCuvier(sc_code)
          lesTransferts[i].setAEnvoyerALaBD(true)
          await store.put(lesTransferts[i])
          this.lireIndexDB()
          if (mod[1] == "CREE") {
            this.passerArrosage = false
          }
        }
        if (newTrans[1] == "NEW") {
          const st_id = new Date().getTime() + ""
          await store.put({
            st_id: st_id,
            sc_code: sc_code,
            sl_code: this.sl_code,
            st_numCharge: this.st_numCharge,
            ut_id: this.ut_id,
            mac_id: this.mac_id,
            st_observation: null,
            AEnvoyerALaBD: true,
            DA: []
          })
        }
      }
      db.close
      this.envoyerALaBD()
    },
    desactiveZone() {
      for (const zones of this.zonesAll) {
        if (zones.getZone() != this.nom_groupe && this.nom_groupe != "") {
          zones.desactiveZone(true)
        } else {
          zones.desactiveZone(false)
        }
      }
    },
    async envoyerALaBD() {
      const db = await dbSAB()
      const lesCuviers = (await db.getAll("sab_cuvier")).map((unCuvier: Cuvier) => {
        return new Cuvier(unCuvier)
      })
      const lesTransferts = (await db.getAll("sab_transfert")).map((t: Transfert) => {
        return new Transfert(t)
      })
      axios
        .post(this.$store.state.ajaxurl + "laitiers.php", {
          action: "ENVOYER_BD",
          lesCuviers: lesCuviers.filter((UnCuvier: Cuvier) => UnCuvier.getAEnvoyerALaBD()),
          lesTransferts: lesTransferts.filter((unTransfert: Transfert) => unTransfert.getAEnvoyerALaBD())
        })
        .then(async (reponse: any) => {
          if (reponse.data.Erreur === false) {
            await this.ecrireIndexDB()
          }
        })
      db.close
    },
    stokageCuvier(groupe: string) {
      this.cuvierStokage = []
      this.nom_zone = groupe

      for (const unCuvier of this.cuviers) {
        if (unCuvier.getSituation() == "PS" && this.nom_zone == "FPS") {
          this.cuvierStokage.push(unCuvier)
        } else if (this.nom_zone == unCuvier.getGroupe() && unCuvier.getSituation().split("/")[0] == "Stocker") {
          if (unCuvier.getNbVoyage() == 3) {
            const tempsFroid = unCuvier.verifEstFroid()
            if (tempsFroid < 24 && tempsFroid >= 20) {
              unCuvier.setNbVoyage(1)
            }
          }
          this.cuvierStokage.push(unCuvier)
        } else if (this.nom_zone == "Arrosage PS" && unCuvier.getSituation() == "Arrosage PS") {
          this.cuvierStokage.push(unCuvier)
        } else if (this.nom_zone == "Arrosage CAD" && unCuvier.getSituation() == "Arrosage CAD") {
          this.cuvierStokage.push(unCuvier)
        }
      }
      const leCamion = this.machines.find((m: Machine) => m.mac_id == parseInt(this.mac_id))
      if (leCamion !== undefined) {
        this.choixMachine = []
        if (leCamion.mac_categorie != "CAD" && (groupe == "CAD" || groupe == "Arrosage CAD")) {
          this.ouvertureChoixCamion()
          this.confirmationCamion = true
          this.choixMachine = this.machines.filter((m: Machine) => m.mac_categorie == "CAD")
        } else if (leCamion.mac_categorie == "CAD" && groupe != "CAD" && groupe != "Arrosage CAD") {
          this.ouvertureChoixCamion()
          this.confirmationCamion = true
          this.choixMachine = this.machines.filter((m: Machine) => m.mac_categorie == "PS" || m.mac_categorie == "C" || m.mac_categorie == "PS" || m.mac_categorie == "C")
        } else {
          this.choixMachine = this.machines
          this.dialogue_Stokage = true
        }
      } else {
        this.dialogue_Stokage = true
      }
      if (this.nom_zone == "FPS") {
        this.nom_zone = "PS"
      }
    },
    couleurCuvierStokage(unCuvier: Cuvier) {
      if (unCuvier.getSituation().split("/")[1] == "Nettoyage") {
        return "grey darken-1"
      }
      if (unCuvier.getDernierArrosage().getDebutArrosage() != "" && (unCuvier.getDernierArrosage().getFinArrosage() == "" || unCuvier.getDernierArrosage().getFinArrosage() == null)) {
        return "blue"
      }
      if (unCuvier.getNbVoyage() >= 3) {
        return "red"
      }
      if (unCuvier.getGroupe() == "C") {
        if (unCuvier.getNbVoyage() == 1) {
          return "yellow"
        }
        if (unCuvier.getNbVoyage() == 2) {
          return "orange"
        }
      }
      return "primary"
    },
    async ouvrirDialogue_tableauDeBord() {
      await this.lireIndexDB()
      this.tableauDeBord = []
      const poste_actuel = this.$store.state.lePosteActuel
      const dateDeDebut = poste_actuel.sp_datetimeDebut.substr(0, 16) + ":00"
      for (const unTransfert of this.transferts) {
        const laDA = unTransfert.getDerDA()
        if (unTransfert.getUtilisateur() == this.ut_id && (laDA.getDateHeureArriver() == null || laDA.getDateHeureArriver() >= dateDeDebut)) {
          this.tableauDeBord.push(unTransfert)
        }
      }
      this.dialogue_tableauDeBord = true
    },
    ouvrirDialogue_numCharge(nom_cuvier: string, mode: string, st_id: string) {
      this.nom_cuvier = nom_cuvier
      if (mode == "MODIFIER") {
        let x = 0
        let trouve = false
        while (x < this.transferts.length && trouve == false) {
          if (this.transferts[x].getId() == st_id) {
            trouve = true
            const trans = this.transferts[x]
            if (trans != undefined) {
              const st_numCharge = trans.getNumCharge()
              const sl_code = trans.getCodeLaitier()
              if (st_numCharge != null) {
                this.st_numCharge = st_numCharge
              }
              if (sl_code != null) {
                this.sl_code = sl_code
              }
            }
            this.modifNumCharge = true
          }
          x++
        }
      }
      if (this.nom_zone.substring(0, 3) != "CAD" && this.nom_zone.substring(0, 2) != "PS") {
        this.dialogue_numCharge = true
      } else {
        this.boutonNumCharge(this.nom_cuvier, "CamionLaitier", this.nom_zone, "CREE")
      }
    },
    ouvrirDialogue_viderCuvier(nom_zone: string) {
      if ((nom_zone == "Batiment" && this.passerArrosage == true) || nom_zone != "Batiment") {
        this.nom_zone = nom_zone
        this.dialogue_ViderCuvier = true
      } else {
        this.snackbarVisible(this.dictionnaire[25])
      }
    },
    ouvrirDialoguePrendreDeposer(nom_zone: string) {
      if (this.camionCuvierLaitier == true && this.camionCuvier == false) {
        this.dialogue_PrendreDeposerStocker = true
        const leCuvier = this.cuviers.findIndex((c: Cuvier) => c.getCode() == this.nom_cuvier)
        this.cuviers[leCuvier].setNbVoyage(3)
        this.nom_zone = nom_zone
      } else if (this.camionCuvier == true && (nom_zone == "Arrosage CAD" || nom_zone == "Arrosage PS")) {
        this.snackbarVisible(this.dictionnaire[59])
      } else {
        this.stokageCuvier(nom_zone)
      }
    },
    ouvrirDialogueAvertisement() {
      this.dialogue_avertissement = true
    },
    ouvrirDialogueArrosage(unCuvier: Cuvier) {
      this.dialogue_arrosage = true
      this.unCuvier = unCuvier
      this.dureeArrosage()
    },
    arrosageEnCoursTerminer() {
      if (this.unCuvier.getArrosages().length != 0 && (this.unCuvier.getDernierArrosage().getFinArrosage() == "" || this.unCuvier.getDernierArrosage().getFinArrosage() == null)) {
        return false
      }
      return true
    },
    dureeArrosage() {
      const leDernierArrosage = this.unCuvier.getDernierArrosage()
      if (leDernierArrosage.getNumerosArrosage() != "" && (leDernierArrosage.getFinArrosage() == "" || leDernierArrosage.getFinArrosage() == null)) {
        leDernierArrosage.setDuree(dateDiff(new Date(leDernierArrosage.getDebutArrosage()), new Date()))
      }
    },
    dureeTotalArrosage() {
      let total = 0
      for (const unArrosage of this.unCuvier.getArrosages()) {
        total = total * 1 + unArrosage.getDuree() * 1
      }
      return total
    },
    async enregistrerArrosage(froid: boolean) {
      let unArrosage = new ArrosageCuvier()
      if (this.unCuvier.getArrosages().length == 0) {
        unArrosage.iniNouveauCycle(this.unCuvier.getCode())
        this.unCuvier.getArrosages().push(unArrosage)
        this.dureeArrosage()
      } else if (
        (this.unCuvier.getDernierArrosage().getFinArrosage() == "" || this.unCuvier.getDernierArrosage().getFinArrosage() == null) &&
        this.unCuvier.getDernierArrosage().getDebutArrosage() != ""
      ) {
        unArrosage = this.unCuvier.getArrosages()[this.unCuvier.getArrosages().length - 1]
        unArrosage.setFinArrosage()
      } else {
        unArrosage.iniNouveauArrosage(this.unCuvier)
        this.unCuvier.getArrosages().push(unArrosage)
        this.dureeArrosage()
      }
      if (froid == true) {
        unArrosage.setFroid()
        this.unCuvier.setNbVoyage(0)
      }
      const db = await dbSAB()
      const store = db.transaction("sab_cuvier", "readwrite").store
      this.unCuvier.setAEnvoyerALaBD(true)
      await store.put(this.unCuvier)
      db.close()
      await this.envoyerALaBD()
    },
    fermerDialogueArrosage() {
      this.dialogue_arrosage = false
      this.unCuvier = new Cuvier()
    },
    ouvrirDialogueCommentaireCuvier(unCuvier: Cuvier) {
      this.dialogue_commentaireCuvier = true
      this.sc_observation = unCuvier.getObservation()
      this.nom_cuvier = unCuvier.getCode()
    },
    enregistrerCommentaire() {
      axios
        .post(this.$store.state.ajaxurl + "laitiers.php", {
          action: "ENREGISTRER_COMMENTAIRE_CUVIER",
          sc_observation: this.sc_observation,
          sc_code: this.nom_cuvier
        })
        .then(() => {
          this.setObservationCuvier()
          this.fermerDilogueCommentaireCuvier()
        })
        .catch(async () => {
          const unCuvier = this.cuviers.find((c: Cuvier) => c.getCode() == this.nom_cuvier)
          if (unCuvier !== undefined) {
            const db = await dbSAB()
            const store = db.transaction("sab_cuvier", "readwrite").store
            unCuvier.setObservation(this.sc_observation)
            unCuvier.setAEnvoyerALaBD(true)
            await store.put(unCuvier)
            db.close
          }
          this.setObservationCuvier()
          this.fermerDilogueCommentaireCuvier()
        })
    },
    setObservationCuvier() {
      const unCuvier = this.cuviers.find((c: Cuvier) => c.getCode() == this.nom_cuvier)
      if (unCuvier !== undefined) {
        unCuvier.setObservation(this.sc_observation)
      }
    },
    fermerDilogueCommentaireCuvier() {
      this.dialogue_commentaireCuvier = false
      this.sc_observation = ""
      this.nom_cuvier = ""
    },
    boutonStockage(leCuvier: Cuvier) {
      this.nom_cuvier = leCuvier.getCode()
      if (leCuvier.getSituation().split("/")[1] == "Nettoyage") {
        this.dialogue_nettoyage = true
      } else if (leCuvier.getDernierArrosage().getDebutArrosage() != "" && (leCuvier.getDernierArrosage().getFinArrosage() == "" || leCuvier.getDernierArrosage().getFinArrosage() == null)) {
        this.snackbarVisible(this.dictionnaire[64])
      } else if (leCuvier.getNbVoyage() >= 3) {
        this.ouvrirDialogueAvertisement()
      } else {
        this.dialogue_PrendreDeposerStocker = true
      }
    },
    boutonNettoyer() {
      const unCuvier = this.cuviers.find((c: Cuvier) => c.getCode() == this.nom_cuvier)
      if (unCuvier !== undefined) {
        unCuvier.setSituation("Stocker", sqlDatetime(new Date()))
        unCuvier.setNbVoyage(0)
        axios
          .post(this.$store.state.ajaxurl + "laitiers.php", {
            action: "ENREGISTRER_NETTOYAGE_CUVIER",
            unCuvier: unCuvier
          })
          .then()
          .catch(async () => {
            const db = await dbSAB()
            const store = db.transaction("sab_cuvier", "readwrite").store
            unCuvier.setAEnvoyerALaBD(true)
            await store.put(unCuvier)
          })
      }
      this.boutonFermerNettoyage()
    },
    boutonAvertissement() {
      if (this.nom_zone == "Arrosage PS" || this.nom_zone == "Arrosage CAD") {
        this.changerSituationCuvier(this.nom_cuvier, "CamionLaitier", this.nom_zone, "CREE")
        this.passerArrosage = true
      } else {
        this.changerSituationCuvier(this.nom_cuvier, "Camion", this.nom_zone, "CREE")
      }
      this.nbVoyage = 0
      this.cuviers[this.cuviers.findIndex((c: Cuvier) => c.getCode() == this.nom_cuvier)].setNbVoyage(0)
      this.dialogue_avertissement = false
      this.dialogue_Stokage = false
    },
    boutonPrendreDeposerStoker() {
      if (this.camionCuvier == true) {
        this.changerSituationCuvier(this.nom_cuvier, "Stocker", this.nom_zone, "MAJ")
      } else if ((this.nom_zone == "Arrosage PS" || this.nom_zone == "Arrosage CAD" || this.nom_zone == "PS") && this.camionCuvier == false && this.camionCuvierLaitier == false) {
        this.changerSituationCuvier(this.nom_cuvier, "CamionLaitier", this.nom_zone, "CREE")
        this.passerArrosage = true
        if (this.nom_zone == "Arrosage PS") {
          this.nom_groupe = "PS"
        } else {
          this.nom_groupe = "CAD"
        }
      } else if (this.camionCuvierLaitier == true) {
        if (this.nom_groupe == "CAD" || this.nom_groupe == "Arrosage CAD") {
          this.changerSituationCuvier(this.nom_cuvier, "Arrosage CAD", this.nom_zone, "MAJ")
        } else if (this.nom_groupe == "PS" || this.nom_groupe == "Arrosage PS") {
          this.changerSituationCuvier(this.nom_cuvier, "Arrosage PS", this.nom_zone, "MAJ")
        } else if (this.nom_groupe == "C") {
          this.snackbarVisible(this.dictionnaire[29])
          this.dialogue_PrendreDeposerStocker = false
        }
      } else {
        this.changerSituationCuvier(this.nom_cuvier, "Camion", this.nom_zone, "CREE")
      }
    },
    boutonPrendreDeposerStokerNettoyage() {
      this.boutonPrendreDeposerStoker()
      const unCuvier = this.cuviers.find((c: Cuvier) => c.getCode() == this.nom_cuvier)
      if (unCuvier !== undefined) {
        unCuvier.setSituation(unCuvier.getSituation() + "/Nettoyage", sqlDatetime(new Date()))
        axios
          .post(this.$store.state.ajaxurl + "laitiers.php", {
            action: "ENREGISTRER_NETTOYAGE_CUVIER",
            unCuvier: unCuvier
          })
          .then()
          .catch(async () => {
            const db = await dbSAB()
            const store = db.transaction("sab_cuvier", "readwrite").store
            unCuvier.setAEnvoyerALaBD(true)
            await store.put(unCuvier)
          })
      }
    },
    boutonFermerNettoyage() {
      this.dialogue_nettoyage = false
      this.nom_cuvier = ""
    },
    verifCamionFour(element: Zone) {
      const leCamion = this.machines.find((m: Machine) => m.mac_id == parseInt(this.mac_id))
      const groupe = element.getGroupeZone()
      this.zone = element
      if (leCamion !== undefined) {
        this.choixMachine = []
        if (leCamion.mac_categorie != "CAD" && (groupe == "CAD" || groupe == "Arrosage CAD")) {
          this.ouvertureChoixCamion()
          this.confirmationCamion = true
          this.choixMachine = this.machines.filter((m: Machine) => m.mac_categorie == "CAD")
          this.mac_id = this.choixMachine[0].mac_id + ""
          return false
        } else if (
          (leCamion.mac_categorie != "C" && leCamion.mac_categorie != "C" && groupe == "C") ||
          (leCamion.mac_categorie != "PS" && leCamion.mac_categorie != "PS" && (groupe == "PS" || groupe == "Arrosage PS"))
        ) {
          this.ouvertureChoixCamion()
          this.confirmationCamion = true
          this.choixMachine = this.machines.filter((m: Machine) => m.mac_categorie == "PS" || m.mac_categorie == "C" || m.mac_categorie == "PS" || m.mac_categorie == "C")
          this.mac_id = this.choixMachine[0].mac_id + ""
          return false
        } else {
          this.choixMachine = this.machines
          return true
        }
      } else {
        return true
      }
    },
    boutonFour(element: Zone) {
      this.nom_zone = element.getCode()
      if (this.verifCamionFour(element) == true) {
        this.zone = new Zone()
        if (this.camionCuvierLaitier == true && this.passerArrosage == false) {
          this.camionCuvier = true
          this.dialogue_PrendreDeposerFour = true
        } else if (this.camionCuvier == true) {
          this.dialogue_PrendreDeposerFour = true
        } else if (element.getNomCuvier() == "") {
          this.snackbarVisible(this.dictionnaire[26])
        } else {
          if (element.getCode() == "PS") {
            this.stokageCuvier("FPS")
          } else {
            this.ouvrirDialogue_numCharge(element.getNomCuvier(), "CREE", "")
          }
        }
      }
    },
    boutonFosse(element: Zone) {
      this.nom_zone = element.getCode()

      this.zone = new Zone()
      if (element.getSituation().split("/")[0] == "Remplissage") {
        if (this.camionCuvierLaitier == true) {
          if (element.getNomCuvier() != "") {
            this.snackbarVisible(this.dictionnaire[62])
          } else {
            this.camionCuvier = false
            this.dialogue_PrendreDeposerFosse = true
          }
        } else if (this.camionCuvier == true) {
          if (element.getNomCuvier() != "") {
            this.snackbarVisible(this.dictionnaire[62])
          } else {
            this.dialogue_PrendreDeposerFosse = true
          }
        } else if (element.getNomCuvier() == "") {
          this.snackbarVisible(this.dictionnaire[26])
        } else if (this.camionCuvier == false && this.camionCuvierLaitier == false) {
          this.nom_cuvier = element.getNomCuvier()
          this.dialogue_PrendreDeposerFosse = true
        }
      } else {
        this.snackbarVisible(this.dictionnaire[62])
      }
    },
    boutonStokageCuvier(sg_code: string, sz_code: string) {
      if (this.camionCuvier == true) {
        this.dialogue_PrendreDeposerStocker = true
      } else if (this.camionCuvierLaitier == true) {
        this.snackbarVisible(this.dictionnaire[60])
        return ""
      } else {
        this.stokageCuvier(sg_code)
      }
      this.nom_groupe = sg_code
      this.nom_zone = sz_code
    },
    boutonFernerStockage() {
      this.dialogue_Stokage = false
      this.confirmationCamion = false
      if (this.camionCuvier == false && this.camionCuvierLaitier == false) {
        this.nom_groupe = ""
      }
      this.$store.dispatch("chargerTables")
    },
    boutonViderCuvier(uneFosse: Zone) {
      if (uneFosse.getSituation().split("/")[0] == "Remplissage") {
        if (uneFosse.getSituation().split("/")[1] == this.derSlCode) {
          if ((this.camionCuvier == false && this.camionCuvierLaitier == false) || (this.camionCuvier == true && this.camionCuvierLaitier == false)) {
            this.snackbarVisible(this.dictionnaire[28])
            this.dialogue_ViderCuvier = false
          } else {
            this.ouvrirDialogue_viderCuvier(uneFosse.getCode())
          }
        } else {
          this.snackbarVisible(this.dictionnaire[61])
        }
      } else {
        this.snackbarVisible(this.dictionnaire[62])
      }
    },
    async boutonNumCharge(sc_code: string, sc_situationNew: string, sz_code: string, debutOuArriver: string) {
      const db = await dbSAB()
      const unCuvier = this.cuviers.find((c: Cuvier) => c.getCode() == this.nom_cuvier)
      if (unCuvier !== undefined) {
        const store = db.transaction("sab_cuvier", "readwrite").store
        unCuvier.setSl_Code(this.sl_code)
        unCuvier.setNumCharge(this.st_numCharge)
        unCuvier.setAEnvoyerALaBD(true)
        await store.put(unCuvier)
      }
      await this.fixerNumCharge()
      await this.fixerTypeLaitier()
      if (this.modifNumCharge == false) {
        this.changerSituationCuvier(sc_code, sc_situationNew, sz_code, debutOuArriver)
      }
      this.dialogue_numCharge = false
      this.modifNumCharge = false
      this.nom_cuvier = ""
    },
    fermerNumCharge() {
      this.dialogue_numCharge = false
      this.nom_cuvier = ""
    },
    async fermerChoixCamion() {
      const db = await dbSAB()
      const co = await db.getAll("sab_co")
      const keyRangeValue = IDBKeyRange.only(co[0].ut_id)
      const machine_utiliser = await db.getAllFromIndex("machine_utiliser", "ut_id", keyRangeValue)
      this.mac_id = machine_utiliser[0].mac_id
      this.mac_desi = machine_utiliser[0].mac_desi
      this.dialogue_choixCamion = false
      this.nom_groupe = ""
      this.nom_zone = ""
      this.desactiveZone()
      this.$store.commit("majCompteur", true)
    },
    ouvertureChoixCamion() {
      this.dialogue_choixCamion = true
      this.$store.commit("majCompteur", false)
    },
    async boutonChoixCamion() {
      const checkListe = await this.enregistrerCheckliste()
      if (checkListe == true) {
        this.dialogue_choixCamion = false
        this.nomMachine(parseInt(this.mac_id))
        if (this.confirmationCamion == true && (this.nom_zone.substring(0, 2) == "PS" || this.nom_zone.substring(0, 1) == "C" || this.nom_zone.substring(0, 3) == "CAD")) {
          this.boutonFour(this.zone)
        } else if (this.confirmationCamion == true) {
          this.dialogue_Stokage = true
        }
        this.$store.commit("majCompteur", true)
      } else if (checkListe == false) {
        this.snackbarVisible(this.dictionnaire[63])
      }
    },
    async fixerNumCharge() {
      const db = await dbSAB()
      this.transferts[this.transferts.length - 1].setNumCharge(this.st_numCharge)
      const leTransfert = this.transferts[this.transferts.length - 1]
      const store = db.transaction("sab_transfert", "readwrite").store
      leTransfert.setNumCharge(this.st_numCharge)
      leTransfert.setAEnvoyerALaBD(true)
      await store.put(leTransfert)
      this.derNumCharge = this.st_numCharge
      this.st_numCharge = ""
      db.close()
      this.lireIndexDB()
    },
    async fixerTypeLaitier() {
      const db = await dbSAB()
      this.transferts[this.transferts.length - 1].setCodeLaitier(this.sl_code)
      const leTransfert = this.transferts[this.transferts.length - 1]
      const store = db.transaction("sab_transfert", "readwrite").store
      leTransfert.setAEnvoyerALaBD(true)
      leTransfert.setCodeLaitier(this.sl_code)
      await store.put(leTransfert)
      this.derSlCode = this.sl_code
      this.sl_code = ""
      db.close()
      this.lireIndexDB()
    },
    affichageFosseCuvier(sz_code: string) {
      const uneZone = this.zones.find((z: Zone) => z.getCode() == sz_code)
      if (uneZone !== undefined) {
        const unCuvier = this.cuviers.find((c: Cuvier) => c.getCode() == uneZone.getNomCuvier())
        if (unCuvier !== undefined) {
          return unCuvier.getCode() + " / " + unCuvier.getSl_Code() + " / " + unCuvier.getNumCharge()
        }
      }
      return "//"
    },
    affichageCodeLaitier() {
      const leCuvier = this.cuviers.find((c: Cuvier) => c.getCode() == this.nom_cuvier)
      if (leCuvier !== undefined) {
        if (this.dialogue_tableauDeBord == true && leCuvier.getGroupe() == "C") {
          return true
        }
      }
      if (this.nom_zone.substring(0, 1) == "C" && this.nom_zone.substring(0, 3) != "CAD") {
        return true
      }
      return false
    },
    couleurFosse(situation: string) {
      if (situation.split("/")[0] == "Remplissage") {
        return "background-color:#8BC34A;"
      } else if (situation == "Pleine") {
        return "background-color: red; background-image: repeating-linear-gradient(45deg, transparent, transparent 35px, rgba(255,255,255,.5) 35px, rgba(255,255,255,.5) 70px);"
      } else if (situation == "Refroidissement eau") {
        return "background-color:#2196F3"
      } else if (situation == "Refroidissement air") {
        return "background-color:#B2EBF2"
      } else if (situation == "Vidange") {
        return "background-color:purple;color:white"
      }
    },
    iniCheckListe() {
      this.checkliste = JSON.parse(JSON.stringify(checklist)).map((c: CheckListeCategorie) => {
        return new CheckListeCategorie(c)
      })
    },
    async verifieCheckListeMachine(mac_id: number) {
      const db = await dbSAB()
      const checkListeDejaFaite = await db.getAll("sab_checkliste")
      db.close()
      for (const uneCheckliste of checkListeDejaFaite) {
        if (new Date().getDate() == new Date(uneCheckliste.cl_date).getDate() && mac_id == uneCheckliste.mac_id) {
          return true
        }
      }
      return false
    },
    async choixCheckListe(mac_id: number) {
      const laMachine = this.machines.find((m: Machine) => mac_id == m.mac_id)
      if (laMachine !== undefined) {
        if ((await this.verifieCheckListeMachine(laMachine.mac_id)) == false) {
          const laCheckListe = this.checkliste.find((c: CheckListeCategorie) => c.getId() + "" == laMachine.mac_categorie)
          if (laCheckListe !== undefined) {
            this.checkListeChoisie = laCheckListe
            this.checkListeLangue(this.$store.state.lang)
          } else {
            this.checkListeChoisie = new CheckListeCategorie()
          }
        } else {
          this.checkListeChoisie = new CheckListeCategorie()
        }
      } else {
        this.checkListeChoisie = new CheckListeCategorie()
      }
    },
    checkCheckliste() {
      let pasCocher = false
      let i = 0
      while (i < this.checkListeChoisie.getCheckListes().length && pasCocher == false) {
        let j = 0
        const uneCategorie = this.checkListeChoisie.getCheckListes()[i]
        while (j < uneCategorie.getControles().length && pasCocher == false) {
          const unCheck = uneCategorie.getControles()[j]
          if (unCheck.getReponse() == null) {
            pasCocher = true
          }
          j++
        }
        i++
      }
      return pasCocher
    },
    async checklisteDejaEnregistrer() {
      const db = await dbSAB()
      const checkliste = await db.getAll("sab_checkliste")
      let existe = false
      let i = 0
      while (i < checkliste.length && existe == false) {
        if (checkliste[i].cl_date.substring(0, 10) == sqlDate(new Date()) && checkliste[i].mac_id == this.mac_id) {
          existe = true
        }
        i++
      }
      return existe
    },
    async enregistrerCheckliste() {
      if (this.checkCheckliste() == false) {
        if ((await this.checklisteDejaEnregistrer()) == false) {
          const db = await dbSAB()
          const co = await db.getAll("sab_co")
          const donneCo = co[0]
          const uneCheckliste = {
            cl_id: new Date().getTime() + "",
            sp_id: donneCo.sp_id,
            cl_date: sqlDatetime(new Date()),
            ut_id: donneCo.ut_id,
            mac_id: this.mac_id,
            cl_donnee: this.checkListeChoisie,
            AEnvoyerALaBD: true
          }
          axios
            .post(this.$store.state.ajaxurl + "laitiers.php", {
              action: "ENREGISTRER_CHECKLISTE",
              uneCheckliste: uneCheckliste
            })
            .then(async () => {
              await this.enregistrerChecklisteIdb(uneCheckliste)
              this.checkListeChoisie = new CheckListeCategorie()
              this.iniCheckListe()
            })
            .catch(async () => {
              await this.enregistrerChecklisteIdb(uneCheckliste)
            })
        }
        return true
      } else {
        return false
      }
    },
    async enregistrerChecklisteIdb(uneChecklist: any) {
      const db = await dbSAB()
      const store = db.transaction("sab_checkliste", "readwrite").store
      await store.put(uneChecklist)
      db.close()
    },
    snackbarVisible(text: string) {
      this.snakbar_text = text
      this.snak_visible = true
    },
    checkListeLangue(lang: string) {
      if (this.checkListeChoisie.getCheckListes().length != 0) {
        for (const uneCheckliste of this.checkListeChoisie.getCheckListes()) {
          uneCheckliste.setTexte(uneCheckliste.getTexteLangue(lang))
          for (const unControle of uneCheckliste.getControles()) {
            unControle.setLibelle(unControle.getLibelleLangue(lang))
          }
        }
      }
    },
    choixlangue(lang: string) {
      this.checkListeLangue(lang)
      switch (lang) {
        case "FR":
          this.dictionnaire = [
            "Stockage cuvier", //0
            "Stockage des cuviers", //1
            "Halle", //2
            "Fosse", //3
            "Arrosage", //4
            "Veuillez sélectionner le camion utilisé:", //5
            "Fermer", //6
            "Sélectionner", //7
            "Prendre le cuvier", //8
            "Déposer le cuvier", //9
            "Charger le cuvier", //10
            "Numéros de charge", //11
            "Type de laitier", //12
            "Avertissement", //13
            "Voulez-vous vraiment prendre ce cuvier alors qu'il n'a pas fini de refroidir.", //14
            "Il reste", //15
            "avant que le cuvier soit froid.", //16
            "Prendre", //17
            "Vider cuvier ", //18
            " dans ", //19
            "Confirmer", //20
            "Oui", //21
            "Non", //22
            "Annuler", //23
            "Fosse", //24
            "Vous ne pouvez pas vider un cuvier encore chaud dans le batiment.", //25
            "Camion vide. Vous n'avez pas de cuvier à déposer", //26
            "Ce cuvier ne peut pas aller là.", //27
            "Vous n'avez aucun cuvier a vider.", //28
            "Vous ne pouvez pas vider ce cuvier ici.", //29
            "Tableau de bord", //30
            "Cuvier", //31
            "Numéros", //32
            "de charge", //33
            "Type de", //34
            "laitier", //35
            "Zone de", //36
            "depart", //37
            "Heure", //38
            "de départ", //39
            "Zone", //40
            "d'arrivée", //41
            "Numéros de charge", //42
            "d'arriver", //43
            "dans", //44
            "A nettoyer", //45
            "Nettoyer", //46
            "Arrosage effectuer", //47
            "Désactiver Arrosage", //48
            "Activer Arrosage", //49
            "Cuvier froid", //50
            "Date début arrosage", //51
            "Date fin arrosage", //52
            "Durée arrosage", //53
            "Froid", //54
            "Commentaire", //55
            "Enregistrer", //56
            "Nettoyage", //57
            "Le cuvier a-t-il bien été nettoyé ?", //58
            "Vous ne pouvez déposer un cuvier vide ici.", //59
            "Vous ne pouvez pas déposer un cuvier remplis.", //60
            "Le laitier de la fosse ne correspont pas au laitier de votre cuvier.", //61
            "Vous ne pouvez pas utiliser cette zone car elle est indisponible.", //62
            "Veuillez compléter la checkliste.", //63
            "Veuillez enlever le cuvier de l'arrosage.", //64
            "Camion" //65
          ]
          break
        case "AL":
          this.dictionnaire = [
            "Abkuhlen kübel", //0
            "Abkuhlen von kübel", //1
            "Gebäude", //2
            "Grube", //3
            "Bewässerung", //4
            "Bitte LKW wählen:", //5
            "Schliessen", //6
            "Auswählen", //7
            "Laden den kübel", //8
            "Entladen Sie den kübel", //9
            "Tank kübel", //10
            "Nummern kübel", //11
            "Schlackenart", //12
            "Warnung", //13
            "Möchtest du diesen kübel wirklich nehmen, sein Abkühlung ist nicht fertig.", //14
            "Er bleibt", //15
            "bevor der kübel kalt ist.", //16
            "Nehmen", //17
            "Leerer kübel", //18
            " in ", //19
            "Bestätigen", //20
            "Ja", //21
            "Nein", //22
            "Abbrechen", //23
            "Grube", //24
            "Sie können nicht ein warme kübel die Halle entleeren.", //25
            "LKW leer. Sie haben kein kübel zu entladen.", //26
            "Diese kübel kann nicht da liegen.", //27
            "Sie haben kein kübel zu entleeren.", //28
            "Sie können nicht diesen kübel hier leeren.", //29
            "Armaturenbrett", //30
            "Kübel", //31
            "Gieß", //32
            "nummern", //33
            "Schlacke", //34
            "typ", //35
            "Abholung", //36
            "punkt", //37
            "Abholung", //38
            "zeit", //39
            "Lieferungs", //40
            "punkt", //41
            "Gießnummern", //42
            "zeit", //43
            "in", //44
            "Zu reinigende", //45
            "Reinigende", //46
            "Bewässerung durchführen", //47
            "Bewässerung ausschalten", //48
            "Bewässerung aktivieren", //49
            "Kübel kalt", //50
            "Datum Beginn Bewässerung", //51
            "Datum Ende Bewässerung", //52
            "Bewässerungsdauer", //53
            "Kalt", //54
            "Kommentar", //55
            "Registrieren", //56
            "Reinigung", //57
            "Wurde kübel gründlich gereinigt?", //58
            "Sie können hier keine leere kübel abstellen.", //59
            "Eine gefüllte kübel können Sie nicht einreichen.", //60
            "Die Schlacke in der Grube stimmt nicht mit der Schlacke in Ihrem kübel.", //61
            "Sie können dieses Feld nicht verwenden, da es nicht verfügbar ist.", //62
            "Bitte füllen Sie die Checkliste aus.", //63
            "Bitte entfernen Sie die Gießkanne.", //64
            "Fahrzeug" //65
          ]
          break
      }
    },
    choixCamionCuvier() {
      if (this.camionCuvier == true) {
        if (this.nbVoyage >= 3) {
          this.imageCamion = "camioncuvierchaud.svg"
        } else if (this.nbVoyage == 2) {
          this.imageCamion = "camioncuvierassezchaud.svg"
        } else if (this.nbVoyage == 1) {
          this.imageCamion = "camioncuviermoinschaud.svg"
        } else {
          this.imageCamion = "camionCuvier.svg"
        }
        this.nomAffichageCamion = this.nom_cuvier
      } else if (this.camionCuvierLaitier == true) {
        if (this.nbVoyage >= 3) {
          this.imageCamion = "camioncuvierremplischaud.svg"
        } else if (this.nbVoyage == 2) {
          this.imageCamion = "camioncuvierrempliassezchaud.svg"
        } else if (this.nbVoyage == 1) {
          this.imageCamion = "camioncuvierremplismoinschaud.svg"
        } else {
          this.imageCamion = "camioncuvierrempli.svg"
        }
        if (this.derNumCharge != "" && this.derSlCode != "") {
          this.nomAffichageCamion = this.nom_cuvier + "/" + this.derNumCharge + "/" + this.derSlCode
        } else if (this.derNumCharge != "") {
          this.nomAffichageCamion = this.nom_cuvier + "/" + this.derNumCharge
        } else if (this.derSlCode != "") {
          this.nomAffichageCamion = this.nom_cuvier + "/" + this.derSlCode
        } else {
          this.nomAffichageCamion = this.nom_cuvier
        }
      } else {
        this.imageCamion = "camion.svg"
        this.nomAffichageCamion = ""
      }
    },
    extraireHeure(dte: string) {
      if (dte != undefined) {
        return dte.substr(11, 5)
      } else {
        return ""
      }
    },
    dateFrancaise(date: string) {
      return dateFrancaise(date)
    },
    dateHeureFrancaise(dateHeure: string) {
      return dateTimeFrancaise(dateHeure)
    }
  },
  created() {
    this.unsubscribe = this.$store.subscribe(mutation => {
      switch (mutation.type) {
        case "majLangue":
          this.choixlangue(this.$store.state.lang)
          break
        case "majTransferts":
          this.transferts = this.$store.state.transferts
          this.initialiser()
          break
        case "majCuvier":
          this.cuviers = this.$store.state.cuvier
          this.initialiser()
          break
      }
    })
  },
  async mounted() {
    this.initialiser()
    this.choixlangue(variables.langue)
  },
  beforeDestroy() {
    this.unsubscribe = []
  }
})
